<script setup>
import { storeToRefs } from 'pinia'
import { usePageBuilder } from '~/stores/page-builder'
import Player from './Player.vue'

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
})

const pageBuilderStore = usePageBuilder()

const { pageType } = storeToRefs(pageBuilderStore)

onBeforeMount(() => {
  if (pageType.value === 'homepage') {
    pageBuilderStore.UPDATE_MEDIA_ID_BY_COMPOENENT(props.data.props.video.id)
  }
})
</script>
<template>
  <player
    :data="data"
    :has-theater-mode="pageType !== 'homepage'"
    :is-type-homepage="pageType === 'homepage'"
  />
</template>
